import * as React from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";

interface LoadingPageProps {
  isOpen: boolean;
}

const LoadingPage = ({ isOpen }: LoadingPageProps) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {}}
      isCentered
    >
      <ModalOverlay />
      <ModalContent boxShadow="none" bg="transparent" alignItems="center">
        <ModalBody>
          <BeatLoader color="#0D3323" size={20} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingPage;
