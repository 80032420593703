import React, { useEffect, useState } from 'react';
import { Box, GridItem, Icon, SimpleGrid, useColorModeValue, Flex, Spinner, Input, Button } from '@chakra-ui/react';
import { Text } from 'styleguide/Typograph';
import { CustomGridItem, HeaderContent, HeaderItem } from "../styles";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdRequestPage, MdApproval, MdCancel } from 'react-icons/md';
import { getOrderSumMetricsAdmin, getOrdersPerDayAdmin, getOrderMetricsAdmin, getAllStoresAdmin } from "services/metricsRequest";
import { notifyError } from 'utils/notification';
import { formatCurrency } from 'helper/currency';
import { CustomSelect } from 'styleguide/Select';
import PaymentMethodsGraph from '../components/PaymentMethodsGraph';
import OrdersPerDayGraph from '../components/OrdersPerDayGraph';
import ListOrders from '../components/ListOrders/ListOrders';

export default function DashboardTable() {
    const [loading, setLoading] = useState(true);
    const [storeArray, setStoreArray] = useState([]);
    const [totalOrders, setTotalOrders] = useState("");
    const [totalPaid, setTotalPaid] = useState("");
    const [totalCancelled, setTotalCancelled] = useState("");
    const [totalPending, setTotalPending] = useState("");
    const [totalChargeback, setTotalChargeback] = useState("");
    const [orderSum, setOrderSum] = useState({});
    const [ordersPerDay, setOrdersPerDay] = useState([]);
    const [lastOrders, setLastOrders] = useState([]);
    const [daysAgo, setDaysAgo] = useState(0);
    const [storeId, setStoreId] = useState('0');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    
    const brandColor = useColorModeValue('#0D3323', '#0D3323');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const dateFilter = startDate && endDate ? `${startDate}_to_${endDate}` : undefined;

    useEffect(() => {
        fetchData();
    }, [storeId, daysAgo]);

    const handleGetTransactions = async (storeId: string, daysAgo: number, dateFilter: string | undefined) => {
        const params: any = { daysAgo, storeId };

        if (dateFilter) {
            params.created_at = dateFilter; 
        }

        const [ordersSumMetrics, ordersPerDayResult, lastOrdersResult] = await Promise.all([
            getOrderSumMetricsAdmin(params),
            getOrdersPerDayAdmin(params),
            getOrderMetricsAdmin(params),
        ]);

        if (ordersSumMetrics.success) {
            const data = ordersSumMetrics.data;

            const completedTotal = Number(data.order_results.completed_total || 0);
            const pendingTotal = Number(data.order_results.pending_total || 0);
            const chargebackTotal = Number(data.order_results.chargeback_total || 0);
            const totalOrders = completedTotal + pendingTotal + chargebackTotal;

            setTotalOrders(formatCurrency(totalOrders));
            setTotalPaid(formatCurrency(completedTotal));
            setTotalCancelled(formatCurrency(parseFloat(data.order_results.cancelled_total) || 0));
            setTotalPending(formatCurrency(pendingTotal));
            setTotalChargeback(formatCurrency(chargebackTotal));
            setOrderSum(data);
        }

        if (ordersPerDayResult.success) {
            setOrdersPerDay(ordersPerDayResult.data);
        }

        if (lastOrdersResult.success) {
            setLastOrders(lastOrdersResult.data);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const stores = await getAllStoresAdmin();

            if (stores.success) {
                setStoreArray(stores.data);
            }

            setLoading(false);
            handleGetTransactions(storeId, daysAgo, undefined); 
        } catch (error) {
            notifyError("Ops! Tivemos um problema ao buscar as métricas.");
        } finally {
            setLoading(false);
        }
    };

    const handleSearchByDate = () => {
        setLoading(true);
        handleGetTransactions(storeId, daysAgo, dateFilter);
        setLoading(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(event.target.value, 10);
        setDaysAgo(selectedValue);
    };

    const handleInputStoreChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setStoreId(selectedValue);
    };

    return (
        <Box>
            {loading ? (
                <Flex direction={"column"}>
                    <Spinner />
                </Flex>
            ) : (
                <React.Fragment>
                    <SimpleGrid columns={{ base: 2, md: 2, lg: 2, '2xl': 4 }} gap='20px' mt='40px'>
                        <GridItem colSpan={{ base: 2, md: 2, lg: 2, '2xl': 4 }}>
                            <HeaderContent>
                                <HeaderItem>
                                    <Text type="h2">Pedidos</Text>
                                </HeaderItem>
                                <Flex direction={{ md: 'row', base: 'column' }}>
                                    <Box mr={{ md: '10px', base: '0' }} mb={{ md: '0', base: '5px' }}>
                                        <CustomSelect
                                            name="banco_id"
                                            onChange={handleInputStoreChange}
                                            required
                                            value={storeId}
                                        >
                                            <option value="0">Todas as lojas</option>
                                            {storeArray.map((value, index) => (
                                                <option key={index} value={value.id}>
                                                    {value.storename}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </Box>
                                    <Box>
                                        <Input
                                            id="start-date"
                                            type="date"
                                            placeholder="Data de Início"
                                            value={startDate}
                                            onChange={(event) => setStartDate(event.target.value)}
                                        />
                                    </Box>
                                    <Box>
                                        <Input
                                            id="end-date"
                                            type="date"
                                            placeholder="Data de Fim"
                                            value={endDate}
                                            onChange={(event) => setEndDate(event.target.value)}
                                        />
                                    </Box>
                                    <Box ml="10px">
                                        <Button
                                            colorScheme="teal"
                                            onClick={handleSearchByDate}
                                        >
                                            Buscar
                                        </Button>
                                    </Box>
                                </Flex>
                            </HeaderContent>
                        </GridItem>

                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics
                                startContent={
                                    <IconBox
                                        w='56px'
                                        h='56px'
                                        bg={boxBg}
                                        icon={<Icon w='32px' h='32px' as={MdRequestPage} color={brandColor} />}
                                    />
                                }
                                name='Pedidos Realizados'
                                value={totalOrders || 0}
                            />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics
                                startContent={
                                    <IconBox
                                        w='56px'
                                        h='56px'
                                        bg={boxBg}
                                        icon={<Icon w='32px' h='32px' as={MdApproval} color={brandColor} />}
                                    />
                                }
                                name='Pedidos Aprovados'
                                value={totalPaid || 0}
                            />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics
                                startContent={
                                    <IconBox
                                        w='56px'
                                        h='56px'
                                        bg={boxBg}
                                        icon={<Icon w='32px' h='32px' as={MdCancel} color={brandColor} />}
                                    />
                                }
                                name='Pedidos Cancelados'
                                value={totalCancelled || 0}
                            />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics name='Pedidos pendentes' value={totalPending || 0} />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics name='Chargeback' value={totalChargeback || 0} />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 2 }}>
                            <OrdersPerDayGraph orders={ordersPerDay} />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 2 }}>
                            <PaymentMethodsGraph methods={orderSum} />
                        </CustomGridItem>
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 2, md: 2, lg: 2, '2xl': 4 }} gap='20px' mt='20px'>
                        <CustomGridItem columns={{ base: 2 }}>
                            <ListOrders orders={lastOrders} />
                        </CustomGridItem>
                    </SimpleGrid>
                </React.Fragment>
            )}
        </Box>
    );
}
