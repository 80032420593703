import React from 'react';
import { ProviderAccount } from 'models/ProviderAccount';
import { Box, Flex, Text } from '@chakra-ui/react';
import ProviderAccountDetails from 'views/admin/acessos/components/ProviderAccountDetails';
import { SellerPaymentType } from 'models/SellerPaymentType';

type ProviderAccountsProps = {
  providerAccounts: ProviderAccount[] | null,
  refetch: Function,
  userId: string,
  sellerPaymentTypes: SellerPaymentType[] | null,
  refetchSellerPaymentTypes: Function,
}

export default function ProviderAccountBox({ providerAccounts, refetch, userId, sellerPaymentTypes, refetchSellerPaymentTypes }: ProviderAccountsProps) {
  const inUseProvider = providerAccounts ? providerAccounts.find((account: ProviderAccount) => account.in_use) : null;
  console.log({ inUseProvider });
  console.log({ providerAccounts });
  return (
    <Box>
      <Flex justify="space-between">
        <Text mb="5px">Contas de Provedores:</Text>
      </Flex>
      {(!providerAccounts || providerAccounts.length === 0) && (
        <Box borderWidth="1px" borderRadius="10px" padding="10px 5px" lineHeight="30px" textAlign="center">
          <Text fontWeight="bold">Nenhuma conta de provedor cadastrada.</Text>
        </Box>
      )}
      {providerAccounts && providerAccounts.map((account: ProviderAccount) => (
        <Box mb="10px">
          <ProviderAccountDetails
            providerAccount={account}
            userId={userId}
            refetch={refetch}
            key={account.id}
            sellerPaymentTypes={sellerPaymentTypes}
            refetchSellerPaymentTypes={refetchSellerPaymentTypes}
          />
        </Box>
      ))}
    </Box>
  );

}
