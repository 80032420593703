// Chakra imports
import { Flex, useColorModeValue, useColorMode } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import logo from '../../../assets/img/logo.png';
import theme from 'theme/theme';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	const { colorMode, toggleColorMode } = useColorMode()

	return (
		<Flex alignItems='center' flexDirection='column' bg={colorMode == "light" && "light"} padding={'10px'}>
			{/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
			<img src={logo} width="175" />
			{/* <HSeparator mb='20px' /> */}
		</Flex>
	);
}

export default SidebarBrand;
