import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Input,
  SimpleGrid,
  Heading,
} from "@chakra-ui/react";
import { useContextUser } from "../../../../hooks/contextApi";
import { useContextLocal } from "../variables/localContext";
import { RowObj } from "../models/rowUserObj";
import Card from "components/card/Card";
import TextFilterValue from "./TextFilterValue";

export default function Users() {
  const { getUserData } = useContextUser();
  const {
    setParams,
    invoicing,
    name,
    situation,
    show,
    status,
    limit,
    page,
  } = useContextLocal();

  // Estados para armazenar a data de início e de fim
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const formatStatus = (value: "0" | "1" | "-") => {
    setParams({ status: value });
  };

  const formatCreatedAt = (value: string) => {
    setParams({ createdAt: value });
  };

  const formatInvoicing: any = {
    "comecando": "Começando agora",
    "10mil": "Até R$ 10.000",
    "100mil": "De R$ 10.000 à R$ 100.000",
    "500mil": "De R$ 100.000 à R$ 500.000",
    "1mi": "De R$ 500.000 à R$ 1.000.000",
    "mais1mi": "Acima de R$ 1.000.000",
  };

  const handleInvoicing = (value: string | undefined) => {
    setParams({ invoicing: value });
  };

  const handleSituation = (value: string) => {
    setParams({ situation: value });
  };

  const runFilter = () => {
    setParams({ page: 1 });
    setParams({ totalPages: 0 });

  const dateFilter =
    startDate && endDate ? `${startDate}_to_${endDate}` : undefined;
    
    getUserData({
      page,
      limit,
      status: status === "1" ? true : status === "0" ? false : undefined,
      situation_id: situation === "-" ? undefined : situation,
      name,
      invoicing: invoicing === "-" ? undefined : invoicing,
      createdAt: dateFilter,
    });

    setParams({ show: !show });
  };

  return (
    <React.Fragment>
      <SimpleGrid columns={2} paddingBottom={10} paddingTop={5} spacing={10}>
        <Box>
          <Input
            id="name"
            type="text"
            placeholder="Busque por um nome"
            value={name}
            onChange={(event) => {
              setParams({ name: event.target.value });
            }}
          />
        </Box>
        <Box>
          <Input
            id="start-date"
            type="date"
            placeholder="Data de Início"
            value={startDate}
            onChange={(event) => setStartDate(event.target.value)}
          />
        </Box>
        <Box>
          <Input
            id="end-date"
            type="date"
            placeholder="Data de Fim"
            value={endDate}
            onChange={(event) => setEndDate(event.target.value)}
          />
        </Box>
      </SimpleGrid>

      {/* Outros filtros */}
      <SimpleGrid columns={4} spacing={10}>
        <Box>
          <Heading fontSize="md">Status</Heading>
          <Divider paddingBottom={1} paddingTop={1} orientation="horizontal" />
          <TextFilterValue
            key={0}
            text="Incompleto"
            value={"0"}
            contextValue={status}
            cb={formatStatus}
          />
          <TextFilterValue
            key={1}
            text="Completo"
            value={"1"}
            contextValue={status}
            cb={formatStatus}
          />
        </Box>
        {/* Situação */}
        <Box>
          <Heading fontSize="md">Situação</Heading>
          <Divider paddingBottom={1} paddingTop={1} orientation="horizontal" />
          <TextFilterValue
            key={2}
            text="Ativo"
            value={1}
            contextValue={situation}
            cb={handleSituation}
          />
          {/* Adicione outros valores para a situação aqui */}
        </Box>
        {/* Faturamento */}
        <Box>
          <Heading fontSize="md">Faturamento</Heading>
          <Divider paddingBottom={1} paddingTop={1} orientation="horizontal" />
          <TextFilterValue
            key={10}
            text="Começando agora"
            value={"Começando agora"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
          {/* Adicione outras opções de faturamento aqui */}
        </Box>
        <Button
          right={0}
          bg={"#008000"}
          color="white"
          onClick={() => {
            runFilter();
          }}
        >
          Procurar
        </Button>
      </SimpleGrid>
    </React.Fragment>
  );
}
