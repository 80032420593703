import React from 'react';
import { Box, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import ProvidersBalance from 'views/admin/accountsBalance/components/ProvidersBalance/ProvidersBalance';
import SellersBalanceMenu from 'views/admin/accountsBalance/components/SellersBalance/SellersBalanceMenu';
import ChargebackAlertsMenu from 'views/admin/accountsBalance/components/ChargebackAlerts/ChargebackAlertsMenu';

export default function AccountsBalanceMenu() {
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
				<Tabs isFitted variant='enclosed' isLazy>
					<TabList>
						<Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Sellers</Tab>
						<Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Provedores</Tab>
						<Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Alertas de Chargeback</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<SellersBalanceMenu />
						</TabPanel>
						<TabPanel>
							<ProvidersBalance />
						</TabPanel>
						<TabPanel>
							<ChargebackAlertsMenu />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</SimpleGrid>
		</Box>
	);

}
