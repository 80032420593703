import React, { useEffect, useState } from "react";
import {
  Button,
  ModalContent,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Input
} from "@chakra-ui/react";
import { useContextUser } from "../../../../hooks/contextApi";
import { useContextPlan } from "../../../../hooks/membershipContext";
import { useContextLocal } from "../variables/localContext";
import { RowObj } from "../models/rowMembershipObj";
import Card from "components/card/Card";

export default function Users() {
  // Chakra Color Mode
  const { show, setParams, idPlan } = useContextLocal();
  const { getUserData } = useContextUser();
  const { createMembershipPlan, getMembershipPlan, updateMembershipPlan } = useContextPlan();
  const [name, setName] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("");

  const { setOpenUpdateModal, openUpdateModal } = useContextLocal();

  const updatePlan = async () => {
    await updateMembershipPlan({ id: idPlan, totalPrice: Number(totalPrice) });
    await getMembershipPlan();
    alert("Valor do plano alterado com sucesso");
    onClose();
  };


  const onClose = () => {
    setOpenUpdateModal(false);
  }

  return (
    <React.Fragment>
      <Modal isCentered isOpen={openUpdateModal} onClose={()=> {onClose()}}>
        Teste
        <ModalContent>
          <ModalHeader>Informe o novo valor do plano</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Input
              id="totalPrice"
              type="text"
              placeholder="Coloque somente números e um ponto para definir os centavos"
              value={totalPrice}
              onChange={(event) => {
                setTotalPrice(event.target.value);
              }}
            />
          </ModalBody>
          <ModalFooter>
          <Button bg={'green'} color={'white'} onClick={()=> {updatePlan()}}>Salvar</Button>
          <Button bg={'red'} color={'white'} onClick={()=> {onClose()}}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}
